import Swiper, { Navigation, Pagination } from 'swiper';

class TestimonialSlider {
  Slider: Swiper;

  constructor() {
    this.slider();
  }

  slider() {
    this.Slider = new Swiper('.testimonial__slider', {
      modules: [Navigation, Pagination],
      spaceBetween: 100,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
    
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      slidesPerView: 1,
      // breakpoints: {
      //   576: {
      //     slidesPerView: 2,
      //     slidesPerGroup: 2,
      //   },
      //   768: {
      //     slidesPerView: 3,
      //     slidesPerGroup: 3,
      //   },
      //   992: {
      //     allowTouchMove: false
      //   },
      // }
    });
  }
}

new TestimonialSlider;